import React from 'react'
import './styles/Projects.scss'
import Card from '../components/Card'
import banner from '../assets/banner.jpg'
import picacho from '../assets/picachofarmers.png'
import practiapp from '../assets/practiapp.png'
import cosmetic from '../assets/cosmeticroommayoreo.png'
import { FaWordpress } from "react-icons/fa";
import { SiWoo } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { IoLogoFirebase } from "react-icons/io5";



const Projects = () => {
  return (
    <>
    <div className='projects-container' id='Projects'>
      <h1>My work</h1>
      <div className='cards-container'>
        <Card
          url = 'https://picachofarmers.mx'
          img = {picacho}
          titulo = 'picachofarmers.mx'
          desc = {[
            'Landing page for agricultural group PicachoFarmers', <br/>,
            <li>Calendar with growing seasons</li>,
            <li>Custom contact form</li>
          ]}
          tec1 = {<FaWordpress size={30} />}
        />
        <Card
          url = 'https://cosmeticroommayoreo.com'
          img = {cosmetic}
          titulo = 'cosemticroommayoreo.com'
          desc = {[
            'ECommerce', <br />,
            <li>Rules for special pricing</li>,
            <li>Link by WhatsApp</li>
          ]}
          tec1 = {<FaWordpress size={30} />}
          tec2 = {<SiWoo size={40} />}
        />
      </div>
    </div>
    <div className='projects-container personalProjects'>
      <h1>Projects</h1>
      <div className='cards-container'>
        <Card
          url = 'https://github.com/FelipeRubioo/PractiApp'
          img = {practiapp}
          titulo = 'PractiApp'
          desc = {[
            'App to register and request internships at Unison', <br/>,
            <li>Team work</li>,
            <li>Firebase database</li>,
          ]}
          tec1 = {<FaReact size={30} />}
          tec2 = {<IoLogoFirebase size={30} />}
        />
      </div>
    </div>
    </>
  )
}

export default Projects